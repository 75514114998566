import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {
  ContactForm,
  TemplateBreadCrumbs,
  Typography,
  Divider,
  MdxWrapper,
  Map,
  PressOfficeWidget,
  Social,
} from '../../components'
import { graphql } from 'gatsby'
import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'

const Contact = ({ data }) => {
  const {
    frontmatter: { header },
  } = data.aboutPage

  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = async values => {
    const res = await axios.post(
      'https://5irxz0ypw3.execute-api.us-west-2.amazonaws.com/Prod/submitForm',
      values,
    )
    if (res.status === 200) {
      setSubmitted(true)
    }
  }

  const breadcrumbs = [
    { label: 'About Us', url: '/about-us/' },
    { label: 'Contact Us' },
  ]

  return (
    <>
      <LayoutFullWidth header={header}>
        <div className="page-wrapper container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-8">
              <TemplateBreadCrumbs breadcrumbs={breadcrumbs} className="mt-5" />
              <Divider thickness={4} />
              <MdxWrapper>
                <Typography tag="h1">Get in Contact</Typography>
                <strong>Office Email:</strong> berthacentre.gsb@uct.ac.za
                <Typography tag="h4">Physical Address</Typography>
                <address>
                  Bertha Centre for Social Innovation & Entrepreneurship <br />
                  Graduate School of Business <br />
                  University of Cape Town <br />
                  8 Portswood Road <br />
                  Green Point <br />
                  Cape Town
                </address>
                <Typography tag="h4">Postal Address</Typography>
                <address>
                  Graduate School of Business <br />
                  University of Cape Town <br />
                  Private Bag X3 <br />
                  Rondebosch <br />
                  7701 <br />
                  South Africa
                </address>
              </MdxWrapper>
            </div>
            <div className="col-span-4">
              <Social orientation="horizontal" />
              <Divider thickness={4} />
              <PressOfficeWidget />
            </div>
          </div>
        </div>

        <div className="my-8">
          <div className="page-wrapper container">
            <Typography tag="h2">Find Us Easily</Typography>
          </div>
          <Map lat={-33.911321} lng={18.417066} zoom={17}></Map>
        </div>

        <div className="page-wrapper container">
          <div className="my-8">
            <Typography tag="h2" className="my-8">
              We Would Love to Hear From You
            </Typography>
            {submitted ? (
              <p>
                Thank you for your message! We will get back to you as soon as
                possible.
              </p>
            ) : (
              <ContactForm onSubmit={handleSubmit} />
            )}
          </div>
        </div>
      </LayoutFullWidth>
    </>
  )
}

Contact.propTypes = {
  data: PropTypes.shape({
    aboutPage: PropTypes.any,
  }),
}

export default Contact

export const pageQuery = graphql`
  query ContactPageQuery {
    aboutPage: mdx(fields: { slug: { eq: "/about-us/" } }) {
      frontmatter {
        ...headerFields
      }
    }
  }
`
